















































import { changepwd } from "@/shared/dtos/changepwd";
import { MessageService } from "@/shared/services/message-service";
import usuarioModule from "@/store/modules/usuario-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
  $_veeValidate: { validator: "new" },
})
export default class ChangePwd extends Vue {
  public cambio: changepwd = new changepwd();
  public created() {
    usuarioModule.getmiusuario();
  }

  public get mi_usuario() {
    return usuarioModule.mi_usuario;
  }

  public cancelar() {
    this.$emit("close");
    this.cambio = new changepwd();
  }

  /**
   * aceptar
   */
  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.cambio.id = this.mi_usuario.id;
        console.log(this.cambio);
        usuarioModule.change_pwdusuario(this.cambio).then(() => {
          MessageService.toast(
            "Contraseña cambiada correctamente",
            MessageService.TypeSuccess
          );
          this.$emit("close");
        });
      }
    });
  }
}
